body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  color: #2d2d2d;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* TODO: add proper CSS reset */
button {
  font: inherit;
  font-size: 100%;
}

ul {
  margin-bottom: 0;
}

code {
  background-color: rgba(247, 171, 27, 0.15);
  font-size: 10pt;
  padding: 3px;
  border-radius: 3px;
  font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace, serif;
  display: inline-block;
  width: auto;
  color: #2d2d2d;
}
