#update-browser-message {
  height: 100vh;
  text-align: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-content: center;
  padding: 150px;
  background: #7a7fff;
  background: -moz-linear-gradient(left, #7a7fff 0%, #058ed8 100%);
  background: -webkit-linear-gradient(left, #7a7fff 0%, #058ed8 100%);
  background: linear-gradient(to right, #7a7fff 0%, #058ed8 100%);
  background: #44689a;
}

#update-browser-message p,
#update-browser-message a {
  color: #fff;
  margin: 0 auto;
  font-size: 20px;
}

#update-browser-message p.head {
  font-size: 35px;
  margin-bottom: 20px;
}

#update-browser-message a {
  text-decoration: underline;
}
